import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Button, Spinner } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers';
import StakingABI from '../abis/StakingABI.json';
import { ERC20_ABI } from "../abis/erc20";
import SETTINGS from "../SETTINGS";
import logoToken from '../logoToken.png';
import { CustomToast, useCustomToast } from '../components/CustomToast';


const STAKE_DURATIONS = {
    "ONE_MONTH": [2592000, 2.5, 30],  // 2.5% per month, ~30% APY
    "THREE_MONTHS": [7776000, 10, 40], // 10% per 3 months, ~40% APY
    "SIX_MONTHS": [15552000, 40, 80], // 40% per 6 months, ~80% APY
    "ONE_YEAR": [31536000, 120, 120], // 120% per year, 120% APY
    "TWO_YEARS": [63072000, 400, 200], // 400% per 2 years, 200% APY
    "FIVE_YEARS": [157680000, 1500, 300], // 1500% per 5 years, 300% APY
};

const STAKE_DURATIONS_NEW = {
    "ONE_MONTH": [2592000, 2.5, 10],  // 2.5% per month, ~30% APY
    "THREE_MONTHS": [7776000, 10, 10], // 10% per 3 months, ~40% APY
    "SIX_MONTHS": [15552000, 40, 20], // 40% per 6 months, ~80% APY
    "ONE_YEAR": [31536000, 120, 30], // 120% per year, 120% APY
    "TWO_YEARS": [63072000, 400, 50], // 400% per 2 years, 200% APY
    "FIVE_YEARS": [157680000, 1500, 300], // 1500% per 5 years, 300% APY
};

const StakingOld = ({ provider, account, isConnected }) => {
    const [tokenBalance, setTokenBalance] = useState('0');
    const [stakes, setStakes] = useState([]);
    const [amountToStake, setAmountToStake] = useState('');
    const [durationToStake, setDurationToStake] = useState("THREE_MONTHS");
    const [loading, setLoading] = useState(false);
    const [txMessage, setTxMessage] = useState("");
    const [stakedAmount, setStakedAmount] = useState('0');
    const [staked, setStaked] = useState(0);
    const [contractBalance, setContractBalance] = useState(0);
    const { toast, showToast, hideToast } = useCustomToast();
    async function getBalance() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
        const TokenBalance = await TokenContract.balanceOf(account);
        const TokenDecimals = await TokenContract.decimals();
        setTokenBalance(formatUnits(TokenBalance, TokenDecimals));
        const ContractBalance = await TokenContract.balanceOf(
            SETTINGS.stakingContractOld
          );
        setContractBalance(formatUnits(ContractBalance, 18));
    }

    async function getRewardPool() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(SETTINGS.stakingContractOld, StakingABI, signer);
        const stakedAm = await contract.staked();
        setStaked(formatUnits(stakedAm, 18));
    }

    const getStakeDurationName = (firstValue) => {
        for (const [key, value] of Object.entries(STAKE_DURATIONS)) {
            if (value[0] === firstValue) {
                return key;
            }
        }
        return null; // or some default value
    };
    useEffect(() => {
        if (!provider || !account) return;
        fetchStakes();
        getBalance();
        getRewardPool();
    }, [provider, account]);
    const fetchStakes = async () => {
        setLoading(true);
        try {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.stakingContractOld, StakingABI, signer);
            const stakesDataUser = await contract.getUserStakes(account);
            const serialized = JSON.stringify(stakesDataUser, (key, value) =>
                typeof value === 'bigint' ? value.toString() : value
            );
            const stakesData = JSON.parse(serialized);
            let stakesArr = [];
            for (let i = 0; i < stakesData.length; i++) {
                const stake = stakesData[i];
                const rewardPercent = STAKE_DURATIONS[getStakeDurationName(parseFloat(stake["2"]))][1];
                const amount = formatUnits(stake["0"].toString(), 18);
                const rewardAmount = parseFloat(amount) + (parseFloat(amount) / 100) * rewardPercent;
                if (!stake["3"]) {
                    stakesArr.push({
                        index: i,
                        amount: amount,
                        rewardAmount: rewardAmount,
                        timestamp: stake["1"],
                        duration: stake["2"],
                        claimed: stake["3"],
                        i
                    });
                }
            }
            setStakes(stakesArr);
        } catch (error) {
            console.error("Failed to fetch stakes", error);
        } finally {
            setLoading(false);
        }
    };

    const handleStake = async () => {

        if (!provider || !account) return;
        try {
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            setLoading(true);
            setTxMessage("Approving "+SETTINGS.tokenSymbol+" transaction...");
            const tokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
            const amountToStakeWei = parseUnits(amountToStake.toString(), 18);
            const approveTx = await tokenContract.approve(SETTINGS.stakingContractOld, amountToStakeWei);
            await approveTx.wait();
            setTxMessage("Staking "+SETTINGS.tokenSymbol+" tokens...");
            const stakingContract = new Contract(SETTINGS.stakingContractOld, StakingABI, signer);
            const stakeTx = await stakingContract.stake(amountToStakeWei, STAKE_DURATIONS[durationToStake][0]);
            await stakeTx.wait();
            setTxMessage(""+SETTINGS.tokenSymbol+" staked successfully!");
            await fetchStakes();
            await getBalance();
            setAmountToStake('');
        } catch (error) {
            console.error("Staking failed", error);
            showToast("Staking failed. Please try again.", "danger");
            setTxMessage("Staking failed. Please try again.");
        } finally {
            setLoading(false);
            showToast("Stake successfull!", "success");
            setTimeout(() => setTxMessage(""), 5000);
        }
    };

    const handleClaim = async (stakeIndex) => {
        if (!provider || !account) return;
        try {
            setLoading(true);
            setTxMessage("Claiming rewards...");
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.stakingContractOld, StakingABI, signer);
            await contract.claim(stakeIndex);
            setTxMessage("Rewards successfully claimed!");
            await fetchStakes();
        } catch (error) {
            console.error("Claiming failed", error);
            showToast("Claiming failed. Please try again.", "danger");
            setTxMessage("Claiming failed. Please try again.");
        } finally {
            setLoading(false);
            showToast("Claim successfull!", "success");
            setTimeout(() => setTxMessage(""), 5000);

        }
    };

    const getProgress = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        const timePassed = now - timestamp;
        const progress = (timePassed / duration) * 100;
        return Math.min(progress, 100);
    };

    const getTimeLeft = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        const timePassed = now - timestamp;
        const timeLeft = duration - timePassed;

        if (timeLeft <= 0) {
            return "Time is up";
        }

        const days = Math.floor(timeLeft / (24 * 3600));
        const hours = Math.floor((timeLeft % (24 * 3600)) / 3600);
        const minutes = Math.floor((timeLeft % 3600) / 60);

        return `${days} days, ${hours} hours, and ${minutes} minutes left`;
    };

    const isClaimable = (timestamp, duration) => {
        const now = Math.floor(Date.now() / 1000);
        return (now - timestamp) >= duration;
    };

    const calculateReward = (amount, duration) => {
        const rewardPercent = STAKE_DURATIONS[duration][1];
        return (parseFloat(amount) / 100) * rewardPercent;
    };

    const getAPY = (duration) => {
        return STAKE_DURATIONS[duration][2];
    };

    const isStakeButtonDisabled = () => {
        if (isNaN(parseFloat(amountToStake)) || amountToStake <= 0) {
            return true;
        }
        return false;
    };

    if (loading) {
        return (
            <div className="loaderScreen text-center">
                <br />
                <Spinner animation="border" role="status" className='loaderBig' /><br />

                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }

    return (
        <div>
            <CustomToast show={toast.show} message={toast.message} type={toast.type} onClose={hideToast} />
            <Row className='pt-md-4 pt-2'>
              

                {/* Your stakes */}
                <Col sm={12} md={12} lg={12} className='offset-lg-0 offset-md-2 offset-0 mb-3'>
                   
                    {stakes.map((stake, index) => (
                        <div key={index} className="mb-3 pokemon-card additional-p">
                            <Row>
                                <Col xs={6}>
                                    <p className='mb-2 p small'>Stake size:</p>
                                    <h6>
                                        <img src={logoToken} className='tokenIconColor' style={{ width: "25px", marginRight: "7px", marginTop: "-3px" }} alt={SETTINGS.tokenSymbol} />
                                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.rewardAmount)}  {SETTINGS.tokenSymbol}
                                    </h6>
                                </Col>
                                <Col xs={6}>
                                    <p className='mb-2 p small'>Staked:</p>
                                    <h6>
                                        <img src={logoToken} className='tokenIconColor' style={{ width: "25px", marginRight: "7px", marginTop: "-3px" }} alt={SETTINGS.tokenSymbol} />
                                        {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(stake.amount)} {SETTINGS.tokenSymbol}
                                    </h6>
                                </Col>
                            </Row>
                            <p className='mb-2 mt-3 p small'>Time left:</p>
                            <Col className='mb-4'>
                                <ProgressBar
                                    animated
                                    striped
                                    now={getProgress(stake.timestamp, stake.duration)}
                                    label={`${getProgress(stake.timestamp, stake.duration)}%`}
                                />
                                <div className="aria-valuenow mt-1" style={{ fontSize: "12px", fontWeight: "700" }}>
                                    {getTimeLeft(stake.timestamp, stake.duration)}
                                </div>
                            </Col>
                            <Col>
                                <Button
                                    variant="success"
                                    className="buton"
                                    onClick={() => handleClaim(stake.index)}
                                    disabled={!isClaimable(stake.timestamp, stake.duration) || stake.claimed}
                                >
                                    {stake.claimed ? "Claimed" : (<>
                                        Claim
                                    </>)}
                                </Button>
                            </Col>
                        </div>
                    ))}
                </Col>
            </Row>
        </div>
    );
};

export default StakingOld;
